body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
  font-size: 3.4rem;
  margin-top: 1.5em;
}

th {
  text-align: center;
}

td {
  text-align: center;
}

.container {
  margin-top: 5em;
}

table tr:nth-child(2n) {
  background-color: rgba(7,84,140,0.05);
}

button:hover {
  background-color: rgb(0, 55, 94);
  border-color: rgb(0, 55, 94);
}

.button-primary {
  margin-right: 15px;
}

.page {
  margin-top: 54px;
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow !important;
    color: black !important;
}

.green {
    background-color: green;
}

.array-item-list {
  flex-direction: column;
}

.array-item {
  margin: 10px;
  max-width: 175px;
}

.btn-info {
  width: 175px;
  margin-left: 10px;
}

.btn-add > i {
  display: none;
}

.btn-add::after {
  content: "+";
  font-weight: bold;
  font-size: 25px;
}

.array-item-remove > i {
  display: none;
}

.array-item-remove::after {
  content: "-";
  font-weight: bold;
  font-size: 25px;
}

.col_xs-9 {
  float: left;
}

.col_xs-3 {
  float: right;
}

.page-selection {
  text-align: center;
}

.link.selected {
  font-weight: bold;
  font-size: 22px;
  cursor: default;
}

.link {
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.center {
  text-align: center;
}

#map-area {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

.pswp__zoom-wrap {
  will-change: auto !important;
  -webkit-backface-visibility: visible !important;
}

@media (min-width: 1280px) {
  h1 {
    font-size: 4.6rem;
  }
}

/* Reptail styles */
#reptail {
  padding-top: 14px;
  color: white;
}

#reptail #logo{
	background-image: url("reptail-logo.png") !important;
}

#reptail #top-info {
  color: rgb(61, 61, 61) !important;
}

#reptail table tr:nth-child(2n) {
  background: rgb(255, 255, 255, 0.15);
}

#reptail input {
  color: white;
}

#reptail input[type="submit"] {
  background: #F9CB01 !important;
  color: rgb(61, 61, 61) !important;
}

#reptail select {
  color: white !important;
  border-color: white !important;
}

#reptail select.required {
  background-color: white !important;
  color: rgb(61, 61, 61) !important;
}

#reptail .app-button {
  background: #31ADE3;
  border-color: white;
}

#reptail .app-button p, #reptail .list-table-header {
  color: white;
}

#reptail .app-button img, #reptail .app-button i {
  -webkit-filter: grayscale(100%) brightness(360%) invert(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) contrast(360%) invert(100%);
}

#reptail #header, #reptail #navigate-left, #reptail #menu-top,
#reptail button {
  background: #F9CB01 !important;
  color: rgb(61, 61, 61) !important;
}

#reptail #chat {
  background: rgb(249, 203, 1, 0.9);
  color: rgb(61, 61, 61) !important;
}

#reptail #header i, #reptail #chat-header, #reptail #message-area {
  color: rgb(61, 61, 61) !important;
}


#reptail #top-corner-container, #reptail #chat-button {
  background-color: #31ADE3 !important;
  color: white !important;
}

#reptail #weather, #reptail #weather-overall span,
#reptail .confirm-area, #reptail #load-data-modal {
  background: #31ADE3 !important;
  color: white !important;
}

#reptail #top-contract-and-site-select .selector select {
  border-color: rgb(61, 61, 61) !important;
  color: rgb(61, 61, 61) !important;
}

#reptail #menu-content, #reptail option {
  background: #F9CB01 !important;
  color: rgb(61, 61, 61) !important;
}

#reptail  #menu-content li {
  border-color: rgb(61, 61, 61) !important;
}

#reptail .close {
  background: #F9CB01 !important;
}

#reptail #message-send-button {
  background: rgb(61, 61, 61) !important;
  color: white !important;
}

#reptail #message-type-bar {
  color: rgb(61, 61, 61) !important;
}

#reptail .data-field-top {
  background: #F9CB01 !important;
  color: rgb(61, 61, 61) !important;
}

#reptail .button-view:disabled {
  background-color: rgb(249, 203, 1, 0.6) !important;
}

/* /// */