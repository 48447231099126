input[type=radio],
input.radio {
  margin-right: 30px;
}

.radio {
    display: inline;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 15px;
}

.getGpsButton {
    margin-top: 30px;
}

input[readOnly] {
    border:0;
    font-weight: bold;
}

.all-center {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.datafield {
    margin-bottom: 10px;
}

#location-accuracy-green {
    color: green;
}

#location-accuracy-yellow {
    color: rgb(207, 207, 0);
}

#location-accuracy-red {
    color: red;
}

.button-area {
    text-align: center;
}

.button-view {
    align-content: center;
    text-align: center;
    margin: 1px;
    font-size: 18px;
    width: 200px;
    text-transform: none;
    background-color: rgba(7,84,140,0.5) !important;
    border-color: rgba(7,84,140,0.5) !important;
}

.button-view:disabled {
    background-color: rgb(7,84,140,1) !important;
    opacity: 1;
}

#load-data-modal {
    position: fixed;
    width: 90%;
    height: 90%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

#accuracy-modal {
    position: fixed;
    width: 255px;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

#top-info {
    position: fixed;
    top: 0;
    left: 45px;
    margin-right: 50px;
    color: white;
    z-index: 3;
}

#top-info div {
    display: inline;
}

.distance-info {
    font-size: 15px;
    line-height: 25px;
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

.borders {
    border: 1px rgba(7,84,140,0.9) solid;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 2px;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 5px;
}

span {
    font-size: 15px;
}

.center {
  text-align: center;
}

.wide-area {
    margin: auto;
    width: 90%;
}
  
#load-data-modal {
    position: fixed;
    width: 75%;
    height: 75%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

hr {
    border: 0;
    height: 2px;
    background: rgba(7,84,140,0.9);
    background-image: linear-gradient(to right, white, rgba(7,84,140,0.9), white); 
}

#crosshair-data {
    border-radius: 5px;
    background: rgba(7,84,140,0.9);
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 140px;
    padding: 2px;
}

.state {
    display: inline-block;
    border: 1px solid black;
    background: rgba(7,84,140,0.3);
    padding: 4px;
    color: white;
    width: 70px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.state.selected {
    background: rgba(7,84,140,0.9);
    cursor: auto;
}
